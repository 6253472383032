import React from "react";
import { Layout } from "@/components/layout";
import { graphql, Link } from "gatsby";
import { Arrow } from "@/components/arrow";
import { AiFillLinkedin } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { BsTwitter } from "react-icons/bs";
import { StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { Tabs } from "antd";
import { DiAws, DiDotnet } from "react-icons/di";
import {
  SiApple,
  SiGradle,
  SiJenkins,
  SiSelenium,
  SiReact,
  SiFlutter,
  SiIonic,
  SiSwift,
  SiKotlin,
  SiShopify,
  SiMagento,
  SiWordpress,
  SiJoomla,
  SiDrupal,
  SiMongodb,
  SiMysql,
  SiFirebase,
  SiRealm,
  SiAmazondynamodb,
  SiOracle,
  SiPostgresql,
  SiRedis,
  SiMicrosoftsqlserver,
  SiAngular,
  SiTypescript,
  SiHtml5,
  SiPhp,
  SiJava,
  SiDotnet,
  SiNodedotjs,
  SiRubyonrails,
  SiPython,
  SiXamarin,
} from "react-icons/si";
import { AiFillAndroid } from "react-icons/ai";
import { FaVuejs } from "react-icons/fa";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";

const IndexPage = ({ data }: any) => {
  const {
    site: {
      siteMetadata: { title, lang, description, siteUrl },
    },
    blogs,
  } = data;

  return (
    <Layout>
      <GatsbySeo
        title="Best Mobile App Development Company in Chennai | IT Solutions"
        description="We are Best Mobile App Development Company in Chennai. We also provide complete IT Solutions to Our clients."
        canonical="https://www.tectratechnologies.com/"
        openGraph={{
          locale: "en_US",
          type: "website",
          url: "https://www.tectratechnologies.com/",
          title:
            "Best Mobile App Development Company in Chennai | IT Solutions",
          description:
            "We are Best Mobile App Development Company in Chennai. We also provide complete IT Solutions to Our clients.",
          site_name: "Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
            {
              "@type": "WebPage",
              "@id": "https://www.tectratechnologies.com/",
              url: "https://www.tectratechnologies.com/",
              name: "Best Mobile App Development Company in Chennai | IT Solutions",
              isPartOf: {
                "@id": "https://www.tectratechnologies.com/#website",
              },
              datePublished: "2019-06-18T09:27:59+00:00",
              dateModified: "2022-05-10T17:38:10+00:00",
              description:
                "We are Best Mobile App Development Company in Chennai. We also provide complete IT Solutions to Our clients.",
              breadcrumb: {
                "@id": "https://www.tectratechnologies.com/#breadcrumb",
              },
              inLanguage: "en-US",
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: ["https://www.tectratechnologies.com/"],
                },
              ],
            },
            {
              "@type": "BreadcrumbList",
              "@id": "https://www.tectratechnologies.com/#breadcrumb",
              itemListElement: [
                { "@type": "ListItem", position: 1, name: "Home" },
              ],
            },
          ],
        }}
      />
      <div className="bg-white h-screen flex items-center justify-center my-28 lg:my-10">
        <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
          <div className="text-left my-8 lg:my-20 xl:w-3/5">
            <div className="py-20">
              <h1 className="text-black text-4xl font-poppins font-semibold mb-4">
                Your Vision,
                <br /> Our Technology.
              </h1>
              <div className="relative flex overflow-hidden justify-start items-center text-center">
                <Link
                  to="/contact"
                  className="flex items-center text-xl capitalize hover:text-black"
                  activeClassName="border-b-2 border-black"
                >
                  Talk to Us
                  <Arrow color="black" />
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-8 bg-white bg-opacity-70 shadow-[0_2px_151px_rgba(0,0,0,0.09)]">
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
              <div className="p-8 pb-0 lg:pb-8 font-poppins lg:border-r-2 lg:border-gray-200 text-center">
                <h3 className="text-xl font-medium">What we do</h3>
                <p className="mt-4 text-lg">
                  We build Web, Mobile and Cloud applications for our customers
                  through our team of Nearshore &amp; Offshore developers.
                </p>
              </div>
              <div className="p-8 pb-0 lg:pb-8 font-poppins lg:border-r-2 lg:border-gray-200 text-center">
                <h3 className="text-xl font-medium">Our Mission</h3>
                <p className="mt-4 text-lg">
                  We focus on helping you, at a reasonable cost, to build a
                  strong foundation with software solutions.
                </p>
              </div>
              <div className="p-8 pb-8 font-poppins text-center">
                <h3 className="text-xl font-medium">Follow Us</h3>
                <div className="flex items-center justify-center mt-4">
                  <a
                    href="https://www.linkedin.com/company/tectra-technologies"
                    className="text-black hover:text-black"
                  >
                    <FaFacebook className="mx-4 text-2xl" />
                  </a>
                  <a
                    href="https://www.instagram.com/tectratechnologies"
                    className="text-black hover:text-black"
                  >
                    <RiInstagramFill className="mx-4 text-2xl" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/tectra-technologies"
                    className="text-black hover:text-black"
                  >
                    <AiFillLinkedin className="mx-4 text-2xl" />
                  </a>
                  <a
                    href="https://twitter.com/TectraL"
                    className="text-black hover:text-black"
                  >
                    <BsTwitter className="mx-4 text-2xl" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black relative overflow-hidden mt-20 lg:mt-0">
        <div className="max-w-7xl mx-auto px-0 lg:px-8 relative">
          <div className="grid grid-cols-1 items-center justify-center lg:gap-y-2 lg:grid-cols-12">
            <div className="flex flex-wrap items-center justify-center px-4 lg:px-0 lg:pr-12 sm:mx-0 lg:col-span-6">
              <div className="relative z-10 gap-x-4 lg:px-0 my-8 lg:my-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1">
                <p className="text-lg text-white font-poppins font-thin mb-0 leading-8">
                  We, at Tectra Technologies, collaborate with entrepreneurs and
                  business leaders to bring transformative ideas to reality
                  using different technologies.
                </p>
                <p className="text-lg text-white font-poppins font-thin mt-4 mb-0 leading-8">
                  As a pioneer in the software development market, we cater to
                  the diverse needs of traditional as well as digital businesses
                  while emphasizing performance, accessibility, reliability, and
                  quality.
                </p>
                <p className="text-lg text-white font-poppins font-thin mt-4 mb-0 leading-8">
                  All our specialized services like web development, mobile
                  application development, UI/UX design, cloud computing,
                  DevOps, Quality Assurance, etc. are customized as per varied
                  needs and specifications of business verticals.
                </p>
              </div>
            </div>
            <div className="lg:col-span-6">
              <div className="relative w-auto overflow-hidden lg:mt-0 lg:w-[67.8125rem]">
                <StaticImage
                  src="../images/home-company-hero.png"
                  alt=""
                  layout="constrained"
                  placeholder="blurred"
                  quality={95}
                  height={700}
                  className="w-full h-60 md:h-92 lg:h-auto"
                  formats={["auto", "png"]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white mt-10 lg:mt-12">
        <div className="max-w-7xl mx-auto px-5 lg:px-8">
          <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize tracking-tight">
            Services
          </h2>
        </div>
        <div className="mt-6 lg:mt-12 px-5 lg:px-0">
          <Swiper
            slidesPerView={4}
            spaceBetween={20}
            navigation={true}
            modules={[Navigation]}
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <div className="flex flex-col justify-center font-poppins font-normal rounded">
                <div className="w-full">
                  <Link
                    to={`/services/web-app-development`}
                    className="relative flex overflow-hidden w-full justify-center items-center max-w-full"
                  >
                    <StaticImage
                      src="../images/service-web-development.png"
                      width={400}
                      quality={95}
                      formats={["auto", "png"]}
                      alt="Website Design and Development"
                      className="w-full"
                      loading="eager"
                    />
                    <div className="absolute top-0 left-0 bottom-0 right-0 flex items-end bg-service-gradient">
                      <div className="flex flex-col items-center justify-between w-full p-6 lg:py-12 pr-6">
                        <h3 className="mt-0 text-white text-center font-poppins text-lg font-semibold">
                          Website Design and Development
                        </h3>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col justify-center font-poppins font-normal rounded">
                <div className="w-full">
                  <Link
                    to={`/services/mobile-app-development`}
                    className="relative flex overflow-hidden w-full justify-center items-center max-w-full"
                  >
                    <StaticImage
                      src="../images/service-mobile-development.png"
                      width={400}
                      quality={95}
                      formats={["auto", "png"]}
                      alt="Mobile App Development"
                      className="w-full"
                      loading="eager"
                    />
                    <div className="absolute top-0 left-0 bottom-0 right-0 flex items-end bg-service-gradient">
                      <div className="flex flex-col items-center justify-between w-full p-6 py-12 pr-6">
                        <h3 className="mt-0 text-white text-center font-poppins text-lg font-semibold">
                          Mobile App Development
                        </h3>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col justify-center font-poppins font-normal rounded">
                <div className="w-full">
                  <Link
                    to={`/services/ui-ux-design`}
                    className="relative flex overflow-hidden w-full justify-center items-center max-w-full"
                  >
                    <StaticImage
                      src="../images/service-ui-ux.png"
                      width={400}
                      quality={95}
                      formats={["auto", "png"]}
                      alt="UI/ UX"
                      className="w-full"
                      loading="eager"
                    />
                    <div className="absolute top-0 left-0 bottom-0 right-0 flex items-end bg-service-gradient">
                      <div className="flex flex-col items-center justify-between w-full p-6 py-12 pr-6">
                        <h3 className="mt-0 text-white text-center font-poppins text-lg font-semibold">
                          UI/UX
                        </h3>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col justify-center font-poppins font-normal rounded">
                <div className="w-full">
                  <Link
                    to={`/services/qa-automation`}
                    className="relative flex overflow-hidden w-full justify-center items-center max-w-full"
                  >
                    <StaticImage
                      src="../images/service-qa-automation.png"
                      width={400}
                      quality={95}
                      formats={["auto", "png"]}
                      alt="QA Automation"
                      className="w-full"
                      loading="eager"
                    />
                    <div className="absolute top-0 left-0 bottom-0 right-0 flex items-end bg-service-gradient">
                      <div className="flex flex-col items-center justify-between w-full p-6 py-12 pr-6">
                        <h3 className="mt-0 text-white text-center font-poppins text-lg font-semibold">
                          QA Automation
                        </h3>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col justify-center font-poppins font-normal rounded">
                <div className="w-full">
                  <Link
                    to={`/services/cloud-computing`}
                    className="relative flex overflow-hidden w-full justify-center items-center max-w-full"
                  >
                    <StaticImage
                      src="../images/service-cloud-computing.png"
                      width={400}
                      quality={95}
                      formats={["auto", "png"]}
                      alt="Cloud Computing"
                      className="w-full"
                      loading="eager"
                    />
                    <div className="absolute top-0 left-0 bottom-0 right-0 flex items-end bg-service-gradient">
                      <div className="flex flex-col items-center justify-between w-full p-6 py-12 pr-6">
                        <h3 className="mt-0 text-white text-center font-poppins text-lg font-semibold">
                          Cloud Computing
                        </h3>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col justify-center font-poppins font-normal rounded">
                <div className="w-full">
                  <Link
                    to={`/services/dev-ops-solutions`}
                    className="relative flex overflow-hidden w-full justify-center items-center max-w-full"
                  >
                    <StaticImage
                      src="../images/service-devops.png"
                      width={400}
                      quality={95}
                      formats={["auto", "png"]}
                      alt="DevOps"
                      className="w-full"
                      loading="eager"
                    />
                    <div className="absolute top-0 left-0 bottom-0 right-0 flex items-end bg-service-gradient">
                      <div className="flex flex-col items-center justify-between w-full p-6 py-12 pr-6">
                        <h3 className="mt-0 text-white text-center font-poppins text-lg font-semibold">
                          DevOps
                        </h3>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col justify-center font-poppins font-normal rounded">
                <div className="w-full">
                  <Link
                    to={`/services/digital-360`}
                    className="relative flex overflow-hidden w-full justify-center items-center max-w-full"
                  >
                    <StaticImage
                      src="../images/service-digital-360.png"
                      width={400}
                      quality={95}
                      formats={["auto", "png"]}
                      alt="Digital 360"
                      className="w-full"
                      loading="eager"
                    />
                    <div className="absolute top-0 left-0 bottom-0 right-0 flex items-end bg-service-gradient">
                      <div className="flex flex-col items-center justify-between w-full p-6 py-12 pr-6">
                        <h3 className="mt-0 text-white text-center font-poppins text-lg font-semibold">
                          Digital 360
                        </h3>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className="bg-white mt-10 lg:mt-12">
        <div className="max-w-7xl mx-auto px-5 lg:px-8">
          <h2 className="text-black text-center text-2xl font-poppins font-semibold capitalize">
            Clients
          </h2>
          <div className="mt-6">
            <div className="lg:max-w-5xl mx-auto grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-0 items-center">
              {/* <div className="flex justify-center lg:px-8 lg:py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/client-lacles.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Lacles"
                  />
                </div>
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/client-redalertonline.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Red Alert Online"
                  />
                </div>
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/client-parambriym.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Parambriym"
                  />
                </div>
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <StaticImage
                  src="../images/healthsana_logo.png"
                  height={120}
                  quality={95}
                  formats={["auto", "png"]}
                  alt="Healthsana"
                />
              </div> */}
              <div className="flex justify-center lg:px-8 lg:py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/client-carpenters-finance.png"
                    height={40}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Carpenter's Finance"
                  />
                </div>
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/client-international-justice-mission.png"
                    height={40}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="International Justice Mission"
                  />
                </div>
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/mbf_logo.png"
                    height={50}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="MBF"
                  />
                </div>
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <StaticImage
                  src="../images/ml_corporation_logo.png"
                  height={40}
                  quality={95}
                  formats={["auto", "png"]}
                  alt="ML Corporation"
                />
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <StaticImage
                  src="../images/avmc_logo.png"
                  height={100}
                  quality={95}
                  formats={["auto", "png"]}
                  alt="AVMC"
                />
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <StaticImage
                  src="../images/giec_logo.png"
                  height={80}
                  quality={95}
                  formats={["auto", "png"]}
                  alt="GIEC"
                />
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/client-redalertonline.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Red Alert Online"
                  />
                </div>
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <StaticImage
                  src="../images/rosoda_logo.png"
                  height={80}
                  quality={95}
                  formats={["auto", "png"]}
                  alt="AVIL"
                />
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/client-lacles.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Lacles"
                  />
                </div>
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <StaticImage
                  src="../images/avil_logo.png"
                  height={100}
                  quality={95}
                  formats={["auto", "png"]}
                  alt="AVIL"
                />
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <StaticImage
                  src="../images/maher_logo.png"
                  height={60}
                  quality={95}
                  formats={["auto", "png"]}
                  alt="Meenakshi Academy Of Higher Education &amp; Research"
                />
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <StaticImage
                  src="../images/healthsana_logo.png"
                  height={120}
                  quality={95}
                  formats={["auto", "png"]}
                  alt="Healthsana"
                />
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <StaticImage
                  src="../images/turmeric_love_logo.png"
                  height={60}
                  quality={95}
                  formats={["auto", "png"]}
                  alt="Turmeric Love"
                />
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/client-parambriym.png"
                    height={60}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Parambriym"
                  />
                </div>
              </div>
              <div className="flex justify-center lg:px-8 lg:py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/client-vmc-logo.png"
                    height={80}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="Vinayaga Mission College"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white mt-10 lg:mt-12">
        <div className="max-w-7xl mx-auto px-5 lg:px-8">
          <h2 className="text-black text-center text-2xl font-poppins font-semibold capitalize">
            Company Of Experts
          </h2>
          <div className="mt-6 lg:mt-8">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-0.5 items-center">
              <div className="col-span-1 flex justify-center px-8 py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/iso-27001.png"
                    alt="ISO 270001:2013"
                    height={100}
                    className="w-full"
                    quality={95}
                    formats={["auto", "png"]}
                  />
                </div>
              </div>
              <div className="col-span-1 flex justify-center px-8 py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/iso-9001-2015.png"
                    alt="ISO 9001:2015"
                    height={100}
                    quality={95}
                    className="w-full"
                    formats={["auto", "png"]}
                  />
                </div>
              </div>
              <div className="col-span-1 flex justify-center px-8 py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/iaf.png"
                    alt=""
                    height={150}
                    quality={95}
                    className="w-28 lg:w-full"
                    formats={["auto", "png"]}
                  />
                </div>
              </div>
              <div className="col-span-1 flex justify-center px-8 py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/snowflake.png"
                    alt=""
                    height={100}
                    quality={95}
                    className="w-full"
                    formats={["auto", "png"]}
                  />
                </div>
              </div>
              <div className="col-span-1 flex justify-center px-8 py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/web_development_expert_usa.png"
                    alt=""
                    height={100}
                    quality={95}
                    className="w-full"
                    formats={["auto", "png"]}
                  />
                </div>
              </div>
              <div className="col-span-1 flex justify-center px-8 py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/mobile_app_expert_usa.png"
                    alt=""
                    height={100}
                    quality={95}
                    className="w-full"
                    formats={["auto", "png"]}
                  />
                </div>
              </div>
              <div className="col-span-2 sm:col-span-1 flex justify-center px-8 py-8">
                <div className="text-center">
                  <StaticImage
                    src="../images/clutch_logo.png"
                    alt=""
                    height={100}
                    quality={95}
                    className="w-full"
                    formats={["auto", "png"]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white mt-10 lg:mt-12">
        <div className="max-w-5xl mx-auto px-5 lg:px-8">
          <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
            Tech Stacks
          </h2>
          <div className="mt-6 lg:mt-12 font-poppins font-semibold text-center">
            <Tabs defaultActiveKey="1" centered={true}>
              <Tabs.TabPane
                tab="Mobile"
                key="1"
                className="font-poppins font-normal text-black text-sm"
              >
                <div className="mt-8 grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 gap-12">
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiApple className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        IOS
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <AiFillAndroid className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Android
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiReact className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        React Native
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiFlutter className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Flutter
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiIonic className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Ionic
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiSwift className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Swift
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiKotlin className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Kotlin
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 66 66"
                        width="1.1em"
                        height="1.1em"
                        className="mx-auto text-5xl"
                        fill="#000000"
                        fillRule="evenodd"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <use xlinkHref="#A" x=".5" y=".5" />
                        <symbol id="A" overflow="visible">
                          <path
                            d="M31.912 64C14.717 64 .135 49.476.001 32.17A31.54 31.54 0 0 1 9.214 9.665 32.07 32.07 0 0 1 31.996 0a31.62 31.62 0 0 1 22.447 9.322A31.68 31.68 0 0 1 64 31.702C64.08 49.4 49.858 63.82 32.172 64zm.1-62.325a30.4 30.4 0 0 0-21.584 9.172 29.88 29.88 0 0 0-8.753 21.308c.126 16.4 13.946 30.153 30.228 30.153h.218c16.77-.152 30.268-13.825 30.203-30.597-.076-7.992-3.338-15.622-9.063-21.2a29.85 29.85 0 0 0-21.249-8.837zM7.18 41.36V22.665h4.548v1.675H9.315v15.378h2.412v1.675zm15.435-9.532c0 3.4-2.07 5.352-4.875 5.352s-4.674-2.186-4.674-5.176c0-3.124 1.96-5.327 4.824-5.327s4.724 2.295 4.724 5.15zm-7.588.15c0 2.07 1.04 3.7 2.823 3.7s2.806-1.675 2.806-3.786c0-1.918-.946-3.727-2.806-3.727S15 29.927 15 32.02zM24.2 27.03a15.51 15.51 0 0 1 2.697-.226c1.015-.1 2.036.127 2.915.645.67.395 1.075 1.123 1.055 1.9a2.36 2.36 0 0 1-1.675 2.203c1.195.243 2.053 1.294 2.052 2.513a2.64 2.64 0 0 1-.98 2.094c-.72.637-1.918.946-3.77.946a17.92 17.92 0 0 1-2.278-.134zm1.843 4.012h.946c1.28 0 2.002-.603 2.002-1.466s-.72-1.4-1.9-1.4a5.04 5.04 0 0 0-1.055.075zm0 4.674c.326.034.653.048.98.042 1.2 0 2.245-.452 2.245-1.675s-1.022-1.675-2.303-1.675h-.92zm8.85-8.82h1.85v6.6c0 2.806-1.357 3.744-3.35 3.744a4.95 4.95 0 0 1-1.566-.268l.226-1.5a4.06 4.06 0 0 0 1.147.184c1.055 0 1.675-.486 1.675-2.245zm7.12 5.503v1.282H38.16V32.4zM51 36.753c-.833.332-1.726.486-2.622.452-3.225 0-5.185-2.02-5.185-5.1a5.11 5.11 0 0 1 5.427-5.369 5.88 5.88 0 0 1 2.513.452l-.4 1.474a4.95 4.95 0 0 0-1.993-.394c-2.07 0-3.56 1.298-3.56 3.744 0 2.228 1.315 3.67 3.543 3.67a5.17 5.17 0 0 0 2.02-.377zm5.812-14.138V41.36h-4.548v-1.675h2.412V24.3h-2.412v-1.675z"
                            stroke="none"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                        </symbol>
                      </svg>
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Objective C
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiXamarin className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Xamarin
                      </h4>
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Frontend"
                key="2"
                className="font-poppins font-semibold text-base text-center"
              >
                <div className="mt-8 grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 gap-12">
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiAngular className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Angular JS
                      </h4>
                    </div>
                  </div>
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiReact className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        React JS
                      </h4>
                    </div>
                  </div>
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiTypescript className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Typescript
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <FaVuejs className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Vue
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <DiDotnet className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        WPF
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiHtml5 className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        HTML5
                      </h4>
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Database"
                key="3"
                className="font-poppins font-semibold text-base text-center"
              >
                <div className="mt-8 grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 gap-12">
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiMongodb className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        MongoDB
                      </h4>
                    </div>
                  </div>
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiMysql className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        MySQL
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiMicrosoftsqlserver className="mx-auto text-5xl" />

                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        MSSQL
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiFirebase className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Firebase
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiRealm className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Realm
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiAmazondynamodb className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        DynamoDB
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiOracle className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Oracle
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiPostgresql className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        PostgreSQL
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiRedis className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Redis
                      </h4>
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Backend"
                key="4"
                className="font-poppins font-semibold text-base text-center"
              >
                <div className="mt-8 grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 gap-12">
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiPhp className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        PHP
                      </h4>
                    </div>
                  </div>
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiJava className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Java
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiDotnet className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        .Net
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiNodedotjs className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Node JS
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiRubyonrails className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Ruby On Rails
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiPython className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Python
                      </h4>
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="CMS"
                key="5"
                className="font-poppins font-semibold text-base text-center"
              >
                <div className="mt-8 grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 gap-12">
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiDrupal className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Drupal
                      </h4>
                    </div>
                  </div>
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiJoomla className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Joomla
                      </h4>
                    </div>
                  </div>
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiWordpress className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Wordpress
                      </h4>
                    </div>
                  </div>
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiMagento className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Magento
                      </h4>
                    </div>
                  </div>
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <SiShopify className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Shopify
                      </h4>
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Infra &amp; DevOps"
                key="6"
                className="font-poppins font-semibold text-base text-center"
              >
                <div className="mt-8 grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 gap-12">
                  <div className="flex p-3 px-2 justify-center">
                    <div className="text-center">
                      <DiAws className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        AWS
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiJenkins className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Google Cloud
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiGradle className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Gradle
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiJenkins className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Jenkins
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="2.6em"
                        height="2.6em"
                        className="mx-auto text-black rotate-[360deg]"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="#000000"
                          d="M.295 119.397c24.814 57.004 87.778 66.563 162.925 18.246c34.66 32.08 43.295 70.11 14.374 108.392C162.341 252.451 145.586 256 128 256C57.308 256 0 198.692 0 128c0-2.89.106-5.758.295-8.602Zm117.058-24.744c11.206-46.695 42.495-73.48 91.678-65.737c28.67 23.474 46.97 59.14 46.97 99.084c0 50.395-29.127 93.979-71.46 114.857c38.456-54.784 7.03-112.584-67.188-148.204ZM128.001 0c27.435 0 52.85 8.635 73.687 23.33c-69.951-8.132-102.984 50.175-98.37 130.694c-45.452 13.941-82.48 3.669-102.048-44.047C10.03 47.819 63.427 0 128 0Z"
                        />
                      </svg>
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Appium
                      </h4>
                    </div>
                  </div>
                  <div className="flex py-3 px-2 justify-center">
                    <div className="text-center">
                      <SiSelenium className="mx-auto text-5xl" />
                      <h4 className="mt-4 font-poppins font-normal text-black text-sm">
                        Selenium
                      </h4>
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>

      <div className="relative overflow-hidden bg-black mt-10 lg:mt-12">
        <div className="mx-auto max-w-7xl px-0 lg:px-8 relative">
          <div className="grid grid-cols-1 items-center justify-center lg:gap-y-2 lg:grid-cols-12">
            <div className="lg:col-span-6">
              <div className="relative lg:aspect-[1085/730] w-auto -ml-80 lg:mt-0">
                <StaticImage
                  src="../images/blog-hero.png"
                  alt=""
                  layout="constrained"
                  placeholder="blurred"
                  quality={95}
                  height={800}
                  className="w-full h-60 md:h-92 lg:h-auto"
                  formats={["auto", "png"]}
                />
              </div>
            </div>
            <div className="lg:flex lg:flex-wrap lg:items-center lg:justify-center px-5 lg:px-0 lg:pl-12 sm:mx-0 lg:col-span-6">
              <div className="relative z-10 gap-x-4 lg:px-0 my-8 lg:my-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1">
                <h1 className="text-white text-2xl font-poppins font-medium capitalize">
                  Blogs
                </h1>
                <ul className="mt-0 lg:mt-8 text-white">
                  {blogs.nodes.map((blog: any, index: any) => {
                    return (
                      <li
                        className="py-6 border-b-2 border-gray-700"
                        key={index}
                      >
                        <div>
                          <small className="font-poppins font-normal text-gray-100 text-base">
                            {blog.frontmatter.publishedOn}
                          </small>
                          <h2 className="text-lg font-poppins font-normal text-white mt-1 lg:mt-3">
                            {blog.frontmatter.title}
                          </h2>
                          <Link
                            to={`/blogs/${blog.slug}`}
                            className="flex items-center cursor-pointer rounded-full mx-auto font-poppins font-light mt-2 text-lg text-zambezi text-left capitalize"
                          >
                            <span className="text-white capitalize">
                              Read Now
                            </span>
                            <Arrow color="white" />
                          </Link>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="flex items-center justify-center mt-2 lg:mt-4">
                  <Link
                    to={`/blogs`}
                    className="border-b-2 border-gray-700 mt-4 text-white font-poppins font-thin focus:outline-none hover:text-white capitalize rounded text-base"
                  >
                    View All Blogs
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        lang
        description
        siteUrl
      }
    }
    blogs: allMdx(
      filter: { fileAbsolutePath: { regex: "/data/blogs/" } }
      sort: { fields: frontmatter___publishedOn, order: DESC }
      limit: 3
    ) {
      nodes {
        slug
        frontmatter {
          title
          publishedOn(formatString: "MMMM D, YYYY")
          modifiedOn(formatString: "MMMM D, YYYY")
        }
      }
    }
  }
`;

export default IndexPage;
